@import "~@secretwinks/front-base-css/main.scss";
body {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    margin:0;
    padding:40px 0 60px 0;
    background-color: #f6f6f6;
}
.header {
    position: fixed;
    z-index: 2;
    top:0;
    left:0;
    right:0;
    border-bottom: 1px solid #ddd;
    height: 40px;
    background-color: var(--bg-color-white);
    svg {
        position: absolute;
        left:50%;
        transform: translateX(-50%) translateY(5px);
    }
}
footer {
    position: fixed;
    z-index: 2;
    bottom:0;
    left:0;
    right:0;
    height:60px;
    background-color: var(--bg-color-white);
    border-top:1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    margin:20px 10px; 
}
.asset-content {
    //position: absolute;
    //left:50%;
    //transform: translateX(-50%);
    //top:60px;
    max-width: 464px;
    max-height: 275px;
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    svg,
    img {
        max-width: 464px;
        width: 100%;
        object-fit: cover;
        display: block;
        height: 100%;
        margin: 20px auto;
        transform: translateX(-10px);
    }
    
}

.message {
    max-width: 440px;
    margin: 30px auto;
    background-color: var(--bg-color-white);
    position: relative;
    z-index: 2;
    text-align: center;
    
    padding:8px 10px;
    border-radius: 5px;
    border:1px dotted #ddd;

    h1 {
        font-size:22px;
        color: #161616;
        padding: 0;
        margin:0 0 10px 0;
    }
    p {
        font-size:16px;
        color: #545454;
        padding: 0;
        margin:0;
    }
    a {
        color:#0096fa;
        &:active,
        &:visited,
        &:hover {
            color:#0096fa;
        }
    }
}